import { useState } from 'react';
import { differenceInDays } from 'date-fns';
import { dateToString, stringToDate } from '../utils/functions';
import useProcessCookies from './useProcessCookies';

const getInstallPromptLastSeenAt = promptName => localStorage.getItem(promptName);

const setInstallPromptSeenToday = (promptName, processCookies) => {
  const today = dateToString(new Date()); // .toISOString();

  // themeNameStorage.setAppThemeName(value);

  const cookieValues = {
    [promptName]: today,
  };

  processCookies({ cookieValues });

  // localStorage.setItem(promptName, today);
};

function getUserShouldBePromptedToInstall(promptName, daysToWaitBeforePromptingAgain) {
  const lastPrompt = stringToDate(getInstallPromptLastSeenAt(promptName));
  const daysSinceLastPrompt = differenceInDays(new Date(), lastPrompt); // moment().diff(lastPrompt, 'days');
  return (
    Number.isNaN(daysSinceLastPrompt) ||
    daysSinceLastPrompt >= daysToWaitBeforePromptingAgain
  );
}

const useShouldShowPrompt = (promptName, daysToWaitBeforePromptingAgain = 1) => {
  // // console.log('daysToWaitBeforePromptingAgain: ', daysToWaitBeforePromptingAgain);
  // // console.log('promptName: ', promptName);
  const processCookies = useProcessCookies();
  const [userShouldBePromptedToInstall, setUserShouldBePromptedToInstall] = useState(
    getUserShouldBePromptedToInstall(promptName, daysToWaitBeforePromptingAgain),
  );

  const handleUserSeeingInstallPrompt = () => {
    setUserShouldBePromptedToInstall(false);
    setInstallPromptSeenToday(promptName, processCookies);
  };

  return [userShouldBePromptedToInstall, handleUserSeeingInstallPrompt];
};
export default useShouldShowPrompt;
