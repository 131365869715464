import { routerGetCall } from '../../axios';

const getRequests = (state, params) => {
  const url = `/customer/requests`;
  return routerGetCall(state, url, params);
};
const getRequestHistory = (state, params) => {
  const url = `/cms/request/retrieveTransactionHistory`;
  return routerGetCall(state, url, params);
};
// const getRequestHistory = (state, params) => {
//   const url = `/cms/request/retrieveRequests`;
//   return routerGetCall(state, url, params);
// };
export default { getRequests, getRequestHistory };
