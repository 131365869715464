export const removeAllSpaces = string => {
  if (!string) {
    return '';
  }
  const newString = string.split(' ').join('');
  // // console.log('newString: ', newString);
  return newString;
};

export const toTitleCase = str => {
  if (!str) return '';
  return str.toLowerCase().replace(/(^|[\s-'])\S/g, function(match) {
    return match.toUpperCase();
  });
};

export const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0; // eslint-disable-line no-bitwise

    const v = c === 'x' ? r : (r & 0x3) | 0x8; // eslint-disable-line no-bitwise
    return v.toString(16);
  });
};

export const isString = x => {
  return x !== null && x !== undefined && x.constructor === String;
};

// /^\d{10}$/
export const validateExp = (regex, string) => {
  if (string && string !== '') {
    // // console.log('string: ', string);
    const pattern = new RegExp(regex);
    // // console.log('pattern: ', pattern);
    // // console.log('regex: ', regex);
    // // console.log('pattern.test(string): ', pattern.test(parseInt(string, 10)));
    if (pattern.test(string)) {
      // // console.log('string_test_true: ', string);
      return true;
    }
    return false;
  }
  return true;
};

export const convertCamelToSentence = camelString => {
  if (!camelString) return '';
  const result = camelString.replace(/([A-Z])/g, ' $1');
  const sentence = result.charAt(0).toUpperCase() + result.slice(1);

  return sentence;
};

export const splitCamelCaseToString = (s, joinString = ' ') => {
  if (!s) return '';
  return s.split(/(?=[A-Z])/).join(joinString);
};

export const formatGender = str => {
  // // console.log('str: ', str);
  if (str == null || str === undefined) return null;
  // // console.log('typeof str: ', typeof str);
  if (typeof str === 'string') {
    // // console.log('inside: ', typeof str);
    if (str.toLowerCase() === 'm' || str.toLowerCase() === 'male') return 'Male';

    if (str.toLowerCase() === 'f' || str.toLowerCase() === 'female') return 'Female';
  }

  return null;
};

export const formatTitle = str => {
  if (!str || typeof str !== 'string') return null;
  return (
    str
      .toLowerCase()
      .charAt(0)
      .toUpperCase() + str.toLowerCase().slice(1)
  );
};

export const singularize = word => {
  const endings = {
    ves: 'fe',
    ies: 'y',
    i: 'us',
    zes: '',
    ses: '',
    es: '',
    s: '',
  };
  return word.replace(
    new RegExp(`(${Object.keys(endings).join('|')})$`),
    r => endings[r],
  );
};

export const trimChar = (string, charToRemove) => {
  while (string.charAt(0) === charToRemove) {
    string = string.substring(1);
  }

  while (string.charAt(string.length - 1) === charToRemove) {
    string = string.substring(0, string.length - 1);
  }

  return string;
};

export const toUnicode = str => {
  return str
    .split('')
    .map(function(value, index, array) {
      const temp = value
        .charCodeAt(0)
        .toString(16)
        .toUpperCase();
      if (temp.length > 2) {
        return `\\u${temp}`;
      }
      return value;
    })
    .join('');
};
