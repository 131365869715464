import {
  GET_PRODUCTS_API_REQUEST,
  GET_PRODUCTS_API_SUCCESS,
  GET_PRODUCTS_API_ERROR,
  GET_PRODUCT_DETAILS_API_REQUEST,
  GET_PRODUCT_DETAILS_API_SUCCESS,
  GET_PRODUCT_DETAILS_API_ERROR,
} from '../../constants/productActionNames';
import { getOnlyStringKeys, reduceKeys } from '../../utils/functions';
import { persistKey, domain, investmentLob } from '../../utils/variables';

export const initialState = {
  response: null, // { payload: summaryData },
  isPending: false,
  isError: false,
  isSuccess: false,
  error: null,
  products: [],
  isDetailsPending: false,
  isDetailsError: false,
  isDetailsSuccess: false,
  detailsResponse: null,
  detailsError: null,
  product: null,
  searchKeys: [],
  [persistKey]: null,
};

const createProducts = payloadArray => {
  if (!Array.isArray(payloadArray)) return null;
  const products = payloadArray.map((object, index) => {
    const position = index;

    return {
      ...object,
      index: position,
      id: object?._id || index,
      filterLob: object?.lobCategories?.map(x => x.lob).join(',') || '',
      filterProduct: object?.slug,
    };
  });
  // // console.log('notifications: ', notifications);

  // products.sort((a, b) => {
  // // console.log('b: ', b);
  // // console.log('a: ', a);
  // return b.timestamp.localeCompare(a.timestamp); // a.timestamp >= b.timestamp; // a.id - b.id ||
  // });

  return products;
};

function productsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCTS_API_REQUEST:
      return {
        ...state,
        isPending: true,
        isError: false,
        isSuccess: false,
      };
    case GET_PRODUCTS_API_SUCCESS: {
      // const response = action.payload;
      const products = createProducts(action?.payload) || [];

      const obj = products && products.length > 0 ? products[0] : {};
      const reduceObj = reduceKeys(obj);
      const searchKeys = getOnlyStringKeys(reduceObj);
      searchKeys.push('tags.title');
      searchKeys.push('tags.value');
      searchKeys.push('lobCategories.lob');
      // // console.log('searchKeys: ', searchKeys);
      const nowTime = new Date().getTime();
      return {
        ...state,
        isPending: false,
        isError: false,
        isSuccess: true,
        // response, // newProducts,
        products,
        searchKeys,
        [persistKey]: nowTime,
      };
    }
    case GET_PRODUCTS_API_ERROR:
      return {
        ...state,
        isPending: false,
        isError: true,
        isSuccess: false,
        // error: action.payload,
      };

    case GET_PRODUCT_DETAILS_API_REQUEST:
      return {
        ...state,
        isDetailsPending: true,
        isDetailsError: false,
        isDetailsSuccess: false,
      };
    case GET_PRODUCT_DETAILS_API_SUCCESS: {
      // const response = action.payload;
      const products = createProducts(action?.payload) || [];

      const product = products && products.length > 0 ? products[0] : {};

      const nowTime = new Date().getTime();
      return {
        ...state,
        isDetailsPending: false,
        isDetailsError: false,
        isDetailsSuccess: true,
        detailResponse: action.payload,
        product,
        [persistKey]: nowTime,
      };
    }
    case GET_PRODUCT_DETAILS_API_ERROR:
      return {
        ...state,
        isDetailsPending: false,
        isDetailsError: true,
        isDetailsSuccess: false,
        detailsError: action.payload,
      };
    default:
      return state;
  }
}
export default productsReducer;
