import { useSelector, useDispatch } from 'react-redux';
import { getDigitalSalesApplications } from '../actions/digitalSales';
import { dateToString, toTitleCase } from '../utils/functions';
import { listSelector } from '../selectors';
import { digitalSalesName, agentType, customerType } from '../utils/variables';
// import { loadClaimsCount } from '../utils/variables';

const useDigitalSalesData = ({ infiniteScroll = false } = {}) => {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const selectedClient = useSelector(state => state.auth.selectedClient);
  const customerId =
    auth.usertype === agentType ? selectedClient?.clientNumber : auth?.user?.customer?.id;
  const agentId = auth?.user?.agent?.id || '';
  const defaultOptions = useSelector(state =>
    listSelector.listOptions({ state, name: digitalSalesName, infiniteScroll }),
  );
  const allRows = useSelector(state => state.digitalSales.allRows);

  return ({ nextStartIndex, nextItemCount, nextScrollCount, actionIndex }) => {
    // console.log('actionIndex: ', actionIndex);
    // const limit = count; // stopIndex - startIndex;
    // const offset = startIndex;

    const body = {
      id: customerId,
      agentId: agentId || undefined,
      usertype: auth.usertype || customerType,
      limit: defaultOptions?.itemCount,
      offset: defaultOptions?.startIndex,
      fromDate: dateToString(defaultOptions?.startDate),
      toDate: dateToString(defaultOptions?.endDate),
      lob: toTitleCase(defaultOptions?.lob.toString()), // toTitleCase(defaultOptions?.lob),
      nextItemCount,
      nextScrollCount,
      nextStartIndex,
    };

    // cellMeasurerCache.clearAll();
    // listRef.current.recomputeRowHeights();
    // listRef.current.forceUpdate();

    if (allRows && actionIndex > 0 && infiniteScroll) {
      return dispatch(
        getDigitalSalesApplications.getNextDigitalSalesApplicationsAction(body),
      );
    }
    if (!allRows || actionIndex === 0) {
      return dispatch(
        getDigitalSalesApplications.getDigitalSalesApplicationsAction(body),
      ).catch(err => {
        // console.log('err: ', err);
      });
    }
    return () => {};
  };
};
export default useDigitalSalesData;
