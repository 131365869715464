export const GET_REQUESTS_API_REQUEST = 'GET_REQUESTS_API_REQUEST';
export const GET_REQUESTS_API_SUCCESS = 'GET_REQUESTS_API_SUCCESS';
export const GET_REQUESTS_API_ERROR = 'GET_REQUESTS_API_ERROR';
export const GET_NEXT_REQUESTS_SUCCESS = 'GET_NEXT_REQUESTS_SUCCESS';

export const GET_REQUEST_HISTORY_API_REQUEST = 'GET_REQUEST_HISTORY_API_REQUEST';
export const GET_REQUEST_HISTORY_API_SUCCESS = 'GET_REQUEST_HISTORY_API_SUCCESS';
export const GET_REQUEST_HISTORY_API_ERROR = 'GET_REQUEST_HISTORY_API_ERROR';

export const SUBMIT_REQUEST_API_REQUEST = 'SUBMIT_REQUEST_API_REQUEST';
export const SUBMIT_REQUEST_API_ERROR = 'SUBMIT_REQUEST_API_ERROR';
export const SUBMIT_REQUEST_API_SUCCESS = 'SUBMIT_REQUEST_API_SUCCESS';
