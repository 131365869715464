import {
  GET_REQUEST_HISTORY_API_REQUEST,
  GET_REQUEST_HISTORY_API_SUCCESS,
  GET_REQUEST_HISTORY_API_ERROR,
} from '../../../constants/requestActionNames';

import { getRequestsApi } from '../../../apis/requests';
import { getPayloadResponse } from '../../../reducers/requests';

function getRequestHistoryRequest() {
  return {
    type: GET_REQUEST_HISTORY_API_REQUEST,
    payload: true,
  };
}

function getRequestHistorySuccess(requests, params) {
  return {
    type: GET_REQUEST_HISTORY_API_SUCCESS,
    payload: requests,
    params,
  };
}

function getRequestHistoryError(err) {
  return {
    type: GET_REQUEST_HISTORY_API_ERROR,
    payload: err,
  };
}

export function getRequestHistoryAction(params) {
  // const response = getPayloadResponse();
  return (dispatch, getState) => {
    const state = getState();
    // console.log(state);
    dispatch(getRequestHistoryRequest());
    return getRequestsApi
      .getRequestHistory(state, params)
      .then(data => {
        dispatch(getRequestHistorySuccess(data, params));
        return data;
      })
      .catch(error => {
        dispatch(getRequestHistoryError(error));
        throw error;
        // return response;
      });
  };
}

export default { getRequestHistoryAction };
